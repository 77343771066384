import { IQsrOrder, OrderItemStatusEnum, OrderStatusEnum } from '@/views/QsrOrders/types';
import { useEffect, useMemo, useState } from 'react';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menu/types';
import QsrOrdersService from '@/services/qsrOrders';
import { getAllCategoriesFromOrderData, getAllProductsFromOrderData } from '@/services/utils/qsr';
import { getDateFromUnix } from '@/services/utils/k_time';
import { IRestaurant } from '@/views/OrdersTableView/types';
import QsrBatchCard from '@/components/QsrBatchCard';
import { groupBy, orderBy } from 'lodash';
import { IOrderItemStatus } from '@/components/QsrOrderAction';
import { useLanguageQuery } from 'next-export-i18n';

interface IProps {
    data: IQsrOrder;
    currencyCode: string;
    currencySymbol: string;
    restaurant: IRestaurant | null;
    onBatchChange: (payload: IOrderItemStatus) => void;
    onDone: (status: OrderStatusEnum, ticketId: string, refId: string) => void;
    onPrint: (id: string) => void;
}

export default function QsrTableViewCardElement({
    data,
    currencyCode,
    currencySymbol,
    restaurant,
    onBatchChange,
    onDone,
    onPrint,
}: IProps): JSX.Element {
    const qsrOrdersService = QsrOrdersService.getInstance();
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const [productMap, setProductMap] = useState<{ [key: string]: MenuProductResponse }>({});
    const [productLoading, setProductLoading] = useState(false);
    const [categoryMap, setCategoryMap] = useState<{ [key: string]: MenuCategoryResponse }>({});
    const [categoryLoading, setCategoryLoading] = useState(false);

    useEffect(() => {
        if (!data) {
            return;
        }

        setProductLoading(true);
        qsrOrdersService
            .getProductMap(restaurant?.id || '', { ids: getAllProductsFromOrderData(data.orderData) })
            .then((res) => {
                setProductMap(res);
            })
            .finally(() => {
                setProductLoading(false);
            });
    }, [data]);

    useEffect(() => {
        if (!data) {
            return;
        }

        setCategoryLoading(true);
        qsrOrdersService
            .getCategoryMap(restaurant?.id || '', { ids: getAllCategoriesFromOrderData(data.orderData) })
            .then((res) => {
                setCategoryMap(res);
            })
            .finally(() => {
                setCategoryLoading(false);
            });
    }, [data]);

    const [list, changedStatusCount] = useMemo<[IQsrOrder[], number]>(() => {
        if (!data) {
            return [[], 0];
        }

        const d = orderBy(
            Object.entries(groupBy(data.orderData.items || [], (o) => o.timestamp || 0)).map(([unix, o]) => {
                const batchStatus = o?.[0]?.status || (unix !== '0' ? OrderItemStatusEnum.New : undefined);
                return {
                    ...data,
                    orderData: {
                        ...data.orderData,
                        items: o,
                    },
                    date: unix !== '0' ? getDateFromUnix(Number(unix)) : data.createdAt,
                    batchStatus,
                    batchId: o?.[0]?.uid,
                    batchSort: (batchStatus || '') < OrderItemStatusEnum.New, // sort by status with this
                    note: data.orderData.version === 'v2' ? o?.[0]?.batchNote : o?.[0]?.note,
                };
            }),
            ['date'],
            ['desc'],
        );
        return [d, d.filter((o) => o.batchStatus !== OrderItemStatusEnum.New).length];
    }, [data]);

    return (
        <QsrBatchCard
            data={data}
            items={list}
            currencyCode={currencyCode}
            currencySymbol={currencySymbol}
            productMap={productMap}
            categoryMap={categoryMap}
            loading={productLoading && categoryLoading}
            restaurant={restaurant}
            changedStatusCount={changedStatusCount}
            lang={lang}
            onBatchChange={onBatchChange}
            onDone={onDone}
            onPrint={onPrint}
        />
    );
}
